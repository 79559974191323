<template>
  <div class="project-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="property/maintenance/maintain-orders/page"
    >
      <el-button
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('project.name','工单编号')">
            <el-input v-model.trim="filter.orderCode" clearable></el-input>
          </vm-search>
          <vm-search label="保养类型">
            <vm-dict-select v-model="filter.type" type="maintainType"></vm-dict-select>
          </vm-search>
          <vm-search label="计划保养日期">
            <el-date-picker
              v-model="filter.pdTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>
          <vm-search label="品牌">
            <vm-autocomplete
              :filter.sync="filter.brandName"
              url="basic/elevator-brand/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="型号">
            <vm-autocomplete
              :filter.sync="filter.modelName"
              url="basic/elevator-model/list"></vm-autocomplete>
          </vm-search>


          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search label="维保班组">
            <el-input v-model.trim="filter.teamName" clearable></el-input>
          </vm-search>
          <vm-search label="队长姓名">
            <el-input v-model.trim="filter.teamLeaderName" clearable></el-input>
          </vm-search>
          <vm-search label="队长电话">
            <el-input v-model.trim="filter.teamLeaderPhone" clearable></el-input>
          </vm-search>
          <vm-search label="认领超时时间">
            <el-date-picker
              v-model="filter.atoTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>
          <vm-search label="是否认领超时">
            <el-select v-model="filter.assignedTimeout">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
          <vm-search label="楼盘">
            <vm-autocomplete
              :filter.sync="filter.realEstateName"
              url="real-estate/list"></vm-autocomplete>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="teamName" :label="$l('project.name','维保班组')">
        <el-table-column prop="teamLeaderName" label="队长">
          <template slot-scope="scope">
            <div>
              {{scope.row.teamName}}
            </div>
            <div> {{scope.row.teamLeaderName}}/{{scope.row.teamLeaderPhone}}</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="orderCode" label="工单编号"></el-table-column>
      <el-table-column prop="typeDesc" label="保养类型">
        <template slot-scope="scope">
          {{scope.row.planDate?scope.row.typeDesc:scope.row.typeDesc+'(按需)'}}
        </template>
      </el-table-column>
      <el-table-column prop="planDate" label="计划保养日期"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘/楼宇">
        <template slot-scope="scope">
          {{scope.row.realEstateName}}/{{scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="regCode" label="注册代码"></el-table-column>
      <el-table-column prop="createTime" label="品牌/型号">
        <template slot-scope="scope">
          {{scope.row.brandName}}/{{scope.row.modelName}}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="是否认领超时">
        <template slot-scope="scope">
          <span v-if="scope.row.assignedTimeoutLevel">
            {{scope.row.assignedTimeoutLevel}}级超时(超时时间{{scope.row.assignedTimeoutTime}})
          </span>
          <span v-else>未超时(超时时间({{scope.row.assignedTimeoutTime}}))</span>
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button type="primary" @click="$refs.editPage.open(scope.row)">{{$l("common.detail", "详情")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <maintenanceDispatch ref="maintenanceDispatch" @save-success="getList(-1)"></maintenanceDispatch>
  </div>
</template>
<script>
  import EditPage from "./MaintenanceDetailNew";
  import VmAutocomplete from "@/components/VmAutocomplete";
  import maintenanceDispatch from "@/views/maintenance/maintenance/maintenanceOrder/maintenanceDispatch";
  import {export2Excel} from "@/util";
  import moment from "moment";

  export default {
    components: {VmAutocomplete, EditPage,maintenanceDispatch},
    data() {
      return {
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          orderStatus: "to_be_assigned",
          orderColumnName:"orderCode",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      exportExcelData() {
        const title={
          teamName:"维保班组",
          teamLeaderName:"队长",
          teamLeaderPhone:"联系电话",
          assigneeName:"工单责任人",
          assigneePhone:"电话",
          orderCode:"工单编号",
          orderStatusDesc:"工单状态",
          typeDesc:"保养类型",
          planDate:"计划保养日期",
          assignedTime:"认领时间",
          arrivedTime:"到达时间",
          completedTime:"完成时间",
          customerCompanyName:"使用单位",
          realEstateName:"楼盘",
          buildingName:"楼宇",
          elevatorName:"内部编号",
          regCode:"注册代码",
          brandName:"品牌",
          modelName:"型号",
        };
        this.$http.get("property/maintenance/maintain-orders/page",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`保养工单-未认领-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      handleTime() {
        if(this.filter.pdTime !=null) {
          this.filter.planDateFrom = this.filter.pdTime[0];
          this.filter.planDateTo = this.filter.pdTime[1];
        }
        if(this.filter.atoTime !=null) {
          this.filter.assignedTimeoutTimeFrom = this.filter.atoTime[0];
          this.filter.assignedTimeoutTimeTo = this.filter.atoTime[1];
        }
      },

      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.projectName}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/maintenance/maintain-plan/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.projectName + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
